import React from 'react'
import "./loanCard.css"
import { AiTwotoneStar,AiOutlineInfoCircle } from "react-icons/ai";
import { BsStarHalf } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import { Button, Center, Divider } from '@chakra-ui/react'

const LoanCard = (props) => {
  return (
    <div className='loan_card_container' >
        <div className='flex_loan_card'>
            <div style={{marginTop:"30px"}}>
                <img src={props.logo} alt='loan comapny'/>
            </div>
            <div style={{padding:"0px 0px 0px 20px"}}>
                <p className='loan_heading' style={{paddingBottom:"5px"}}>{props.heading}</p>
                <div style={{display:"flex",paddingBottom:"5px"}}>
                    <div style={{display:"flex",paddingRight:"10px"}}>
                        <AiTwotoneStar style={{color:"rgb(255, 175, 20)",fontSize:"22px"}}/>
                        <AiTwotoneStar style={{color:"rgb(255, 175, 20)",fontSize:"22px"}}/>
                        <AiTwotoneStar style={{color:"rgb(255, 175, 20)",fontSize:"22px"}}/>
                        <AiTwotoneStar style={{color:"rgb(255, 175, 20)",fontSize:"22px"}}/>
                        <BsStarHalf style={{color:"rgb(255, 175, 20)",fontSize:"20px"}}/>
                    </div>
                    <p style={{display:"flex",fontWeight:"400",paddingBottom:"1px"}}>Real time rating <span style={{marginTop:"5px",marginLeft:"10px",color:"gray"}}><AiOutlineInfoCircle/></span></p>
                </div>
                <div style={{display:"flex",fontSize:"14px",fontWeight:"600"}}>
                    <p style={{display:"flex"}}>
                        <span style={{color:"gray",marginTop:"5px"}}><RxDotFilled/></span>
                        <span>1 to 7 years</span>
                    </p>
                    <p style={{display:"flex",paddingLeft:"10px"}}>
                        <span style={{color:"gray",marginTop:"5px"}}><RxDotFilled/></span>
                        <span>No application fees required</span>
                    </p>
                </div>
                <div style={{paddingBottom:"10px"}}>
                    <p>{props.example_loan}</p>
                </div>
            </div>
        </div>
        <div className='flex_loan_card2' >
            <div style={{paddingTop:"10px"}} className='flex_loan_card2_div'>
                <p style={{display:"flex",justifyContent:"center",fontSize:"15px",fontWeight:"400"}}>Interest rate <span style={{marginTop:"5px",marginLeft:"10px",color:"gray"}}><AiOutlineInfoCircle/></span></p>
                <p style={{fontWeight:"700",fontSize:"26px"}}>{props.minInterest}% p.a</p>
                <p style={{fontSize:"15px",fontWeight:"400"}}>fixed up to {props.maxInterest}% p.a</p>
            </div>
            <div style={{paddingTop:"10px"}} className='flex_loan_card2_div'>
                <p style={{display:"flex",justifyContent:"center",fontSize:"15px",fontWeight:"400"}}>Monthly repayment <span style={{marginTop:"5px",marginLeft:"10px",color:"gray"}}><AiOutlineInfoCircle/></span></p>
                <p style={{fontWeight:"700",fontSize:"26px"}}>$622</p>
                <p style={{fontSize:"15px",fontWeight:"400"}}>36 Month</p>
            </div>
            <div style={{paddingTop:"10px"}} className='flex_loan_card2_div'>
                <p style={{display:"flex",justifyContent:"center",fontSize:"15px",fontWeight:"400"}}>Total repayments<span style={{marginTop:"5px",marginLeft:"10px",color:"gray"}}><AiOutlineInfoCircle/></span></p>
                <p style={{fontWeight:"700",fontSize:"26px"}}>$22,978</p>
            </div>
            <div></div>
            <div style={{paddingTop:"20px"}} >
                <Button colorScheme='blue' width="200px">More Details</Button>
                <a target='_blank'  href={props.website}><p>Product Info</p></a>
            </div>
        </div>
    </div>
  )
}

export default LoanCard