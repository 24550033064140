

import Home from './pages/Home';

function App() {
  return (
    <div>
      <Home/>
    </div>
  );
}

export default App;
