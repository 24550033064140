import React from 'react'
import { GrMenu } from "react-icons/gr";
import logo from '../Assets/logo-dark.png'
import { FiSearch } from "react-icons/fi";
import { BsTelephone } from "react-icons/bs";
import "./navbar.css"

const Navbar = () => {

    

  return (
    <div className='navbar_box'>
        <div>
            <div >
                <img src={logo} alt='' className='company_logo'/>
            </div> 
        </div>
    </div>
  )
}

export default Navbar