import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Menu, MenuButton, MenuItem, MenuList, Radio, RadioGroup, Select, Stack, useDisclosure } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { FaChevronDown } from "react-icons/fa";
import {searchLoanData} from '../service/loanSearch'
import "./home.css"
import LoanCard from '../components/LoanCard';
import axios from 'axios';

const Home = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [loanAmount,setLoanAmount] = useState("2600")
  const [age_limit,setAge_limit] = useState("18 years to 24 year")
  const [ageLimit,setAgeLimit] = useState(24)
 const  [loanTerm, setLoanTerm] = useState (2)
  const [loan_term,setLoan_term] = useState("0 years to 2 years") 
  const [loanData, setLoanData] = useState([])
  const [updatedResult, setUpdatedResult] = useState(false)
  const btnRef = React.useRef()

  useEffect(()=>{
       axios.get(
      `https://uptoyou.work/api/lainapalvelut.php?site_name=lainapalvelut.fi&loan_amount=${loanAmount}&loan_time=${loanTerm}&age_limit=${age_limit}`
    )
    .then((res) => {         
       setLoanData(res.data.response)
    })
    .catch((err) => {
      console.log(err);
    });   
  },[updatedResult])

  const handleLoanTerms = (e) =>{    
    setLoan_term(e.term); 
    setLoanTerm(e.id);
};

  let limit_age_arr = [ 
    {id:24,age:"18 years to 24 year"},
    {id:34,age:"25 years to 34 years"},
    {id:44,age:"35 years to 44 years"},
    {id:45,age:"45 years +"}
  ]
let term_loan_arr = [
    {id:2,term:"0 years to 2 years"},
    {id:4,term:"2 years to 4 years"},
    {id:6,term:"4 years to 6 years"},
    {id:8,term:"6 years to 8 years"},
    {id:10,term:"8 years to 10 years"},
    {id:12,term:"10 years to 12 years"},
    {id:15,term:"12 years to 15 years"}
  ]

  return (
    <div className='home'>
        <div >
        <div className='navbar_container'>
        <Navbar />
        </div>

        <div className='filter_sort_div'>
            <div style={{padding:"10px 16px"}}>
                <p className='small_text'>Loan Amount</p>
                <p contentEditable="true" className='bold_text' onChange={setLoanAmount} >{loanAmount}</p>
            </div>
            <div>
                <Menu >
                    <MenuButton as={Button} textAlign="left" variant='outline' border='0px' height="65px" width="100%" padding-top="20px" rightIcon={<ChevronDownIcon w={8} h={8} />}                    
                    >
                        <div>
                            <p className='small_text'>Loan term</p>
                            <p className='bold_text'>{loan_term}</p>
                        </div>
                    </MenuButton>

                    <MenuList>
                        {
                            term_loan_arr.map((item)=>{
                            return  <MenuItem value={loan_term} onClick={(e)=>handleLoanTerms(item)}>
                            {item.term}
                            </MenuItem>
                            })
                        }
                    </MenuList>
                </Menu>
            </div>
            <div>
                <Menu >
                    <MenuButton as={Button} textAlign="left" variant='outline' border='0px' height="65px" width="100%" padding-top="20px" rightIcon={<ChevronDownIcon w={8} h={8} />}>
                        <div>
                            <p className='small_text'>Age Limit</p>
                            <p className='bold_text'>{age_limit}</p>
                        </div>
                    </MenuButton>                    
                    <MenuList>
                    {
                            limit_age_arr.map((item)=>{
                            return  <MenuItem value={ageLimit} onClick={(e)=>{setAge_limit(item.age); setAgeLimit(item.id)}}>
                            {item.age}
                            </MenuItem>
                            })
                        }
                    </MenuList>
                </Menu>
            </div>
            <div style={{padding:"17px 16px",textAlign:"center"}}>
                <p ref={btnRef} colorScheme='teal' onClick={onOpen} className='bold_text'>
                    All Filters
                </p>
                <Drawer
                    isOpen={isOpen}
                    placement='right'
                    onClose={onClose}
                    finalFocusRef={btnRef}
                    size='sm'>
                    <DrawerOverlay />
                    <DrawerContent>
                    <DrawerCloseButton/>
                    <DrawerHeader>All Filters</DrawerHeader>
                    <DrawerBody>
                    <Accordion defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                                <AccordionButton>
                                    <Box as="span" flex='1' textAlign='left'>
                                        <p className='drawer_headings'>Loan Amount</p>
                                    </Box>
                                    <AccordionIcon fontSize="24px"/>
                                </AccordionButton>
                            <AccordionPanel pb={4}>
                                <div>
                                  <p className='drawer_amount_input' contentEditable="true" onChange={setLoanAmount}>{loanAmount}</p>
                                </div>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionButton>
                                <Box as="span" flex='1' textAlign='left'>
                                    <p className='drawer_headings'>Loan Term</p>
                                </Box>
                                <AccordionIcon fontSize="24px"/>
                            </AccordionButton>
                            <AccordionPanel pb={4}>                                
                                <div>
                                    <RadioGroup  value={loan_term} defaultValue={loan_term}>
                                        <div className='grid_radio'>
                                        {
                                            term_loan_arr.map((item)=>{
                                                return <Radio onClick={()=>handleLoanTerms(item)} value={item.term}>{item.term} Year</Radio>
                                            })
                                        }  
                                        </div>
                                    </RadioGroup>
                                </div>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionButton>
                                <Box as="span" flex='1' textAlign='left'>
                                    <p className='drawer_headings'>Age limit</p>
                                </Box>
                                <AccordionIcon fontSize="24px"/>
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <div>
                                    <RadioGroup value={age_limit} >
                                        <div className='grid_radio'>
                                        {
                                            limit_age_arr.map((item)=>{
                                                return <Radio onClick={()=>{setAge_limit(item.age); setAgeLimit(item.id)}} value={item.age} checked={item.age===ageLimit}>{item.age} Year</Radio>
                                            })
                                        }                                            
                                        </div>
                                    </RadioGroup>
                                </div>
                            </AccordionPanel>
                        </AccordionItem>
                        </Accordion>
                    </DrawerBody>

                    <DrawerFooter>
                        <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                            <Button variant='outline' mr={3} onClick={onClose}>
                            Reset All
                            </Button>
                            <Button colorScheme='blue' onClick={()=>{setUpdatedResult(!updatedResult)}}>Update Result</Button>
                        </div>
                    </DrawerFooter>
                    </DrawerContent>
                </Drawer>
            </div>
            <div className='sort_container'>
                <p>Sort by</p>
                <div>
                    <Select placeholder='Default' variant='unstyled' className='bold_text'>
                        <option>Interest rate</option>
                        <option>Review</option>
                        <option>Latest</option>
                    </Select>
                </div>
            </div>
        </div>
        <div style={{display:"grid",gridTemplateColumns:"repeat(1,1fr)",width:"80%",margin:"auto",columnGap:"40px",rowGap:"5px",paddingBottom:"50px"}}>
            {loanData.map((item)=><LoanCard heading={item.heading} rating={item.rating} minInterest={item.min_interest} maxInterest={item.max_interest}  website={item.website}  logo={item.picture_1} example_loan={item.example_loan}  />)}                                        
        </div>

    </div>
    </div>
  )
}

export default Home